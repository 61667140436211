import React from "react";
import Layout from "../components/layout";
import Icon from "../components/icon";
import VFWordmark from "../components/icon/icons/vf-wordmark-rgb-no-spacing.icon";
import PrimaryButton from "../components/button";

const SignUpPage = props => (
  <Layout pageTitle="Sign Up">
    <main
      className={"office absolute top-0 right-0 bottom-0 left-0 flex h-100 items-center justify-center"}>
      <div className="register flex flex-column items-center bg-white pa2 pa5-ns shadow-2">
        <div className="pb2 mb4 tc">
          <Icon icon={VFWordmark} width={"70%"}/>
        </div>
        <h3 className="mb4">Start your free trial today</h3>
        <input className="w-100 mb4"
               type="email"
               placeholder="Your email"
        />
        <div className="w-100">
          <label htmlFor="registerSubmit">
            <PrimaryButton text="Sign Up" extraClasses={"w-100"}/>
          </label>
        </div>
        <div className="f5 w-100 w5-ns tc pt4">No credit card required</div>
      </div>
    </main>
  </Layout>
);

export default SignUpPage;
